import React, { useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import PreviewIcon from "@mui/icons-material/Preview";
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import SearchIcon from "@mui/icons-material/Search";
import moment from "moment";

import {
  Grid,
  TextField,
  Autocomplete,
  ButtonGroup,
  Tooltip,
  Box,
  TableContainer,
} from "@mui/material";
import { Link } from "react-router-dom";
import { BASE_URL } from "../../../global";
import ExcelDownload from "../../../utils/components/excelDownload";
import axiosWithToken from "../../../utils/components/axiosTokenConfig";
import ListWrap from "../../commonComponent/listWrap";

// datatablse packages
var $ = require("jquery");
$.DataTable = require("datatables.net");


let notNeededColumn = ["id"];
const columns = [
  { id: "id", label: "Sr. No.", minWidth: 40 },
  { id: "ulrNo", label: "ULR No.", minWidth: 40 },
  { id: "certificateNumber", label: "Certificate Number", minWidth: 40 },
  {
    id: "InstrumentId",
    label: "Instrument Name",
    minWidth: 40,
    align: "left",
  },
  { id: "range", label: "range", minWidth: 40 },
  { id: "lc", label: "lc", minWidth: 40 },
  { id: "DUCID", label: "DUC ID", minWidth: 40 },
  { id: "make", label: "Make", minWidth: 40 },
  { id: "model", label: "Model", minWidth: 40 },
  { id: "locationOfInstrument", label: "Location Of Instrument", minWidth: 40 },
  {
    id: "calibrationDate",
    label: "Calibration date",
    minWidth: 40,
    align: "left",
  },
  {
    id: "dueDate",
    label: "Due date",
    minWidth: 40,
    align: "left",
  },
];

function createData(
  id,
  ulrNo,
  certificateNumber,
  InstrumentId,
  range,
  lc,
  DUCID,
  make,
  model,
  locationOfInstrument,
  calibrationDate,
  dueDate
) {
  return {
    id,
    ulrNo,
    certificateNumber,
    InstrumentId,
    range,
    lc,
    DUCID,
    make,
    model,
    locationOfInstrument,
    calibrationDate,
    dueDate,
  };
}

export default function SummaryReport() {
  const [page, setPage] = React.useState(0);
  const [customer, setCustomer] = React.useState(0);
  const [data, setData] = React.useState([]);
  const [company, setCompany] = React.useState(null);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [calData, setCalData] = React.useState([]);
  const [ids, setIds] = React.useState([]);
  const [searched, setSearched] = React.useState(false);
  const [certificatesData, setCertificatesData] = React.useState([]);
  const [finalArray, setFinalArray] = React.useState([]);
  const [instId, setInstId] = React.useState(0);
  const [ducId, setDucId] = React.useState("");
  const _clientId = localStorage.getItem("clientId");
  const _userType = localStorage.getItem("type");

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const getCustomer = (event) => {
    let url = BASE_URL;
    let endPoint = "";
    endPoint =
      _userType == "3"
        ? `clients/${_clientId}?_where=(status,eq,1)`
        : `clients?_where=(status,eq,1)`;
    axiosWithToken
      .get(url + endPoint)
      .then((res) => {
        setCustomer(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getInstruments = (id) => {
    let url = BASE_URL;
    axiosWithToken
      .get(
        url +
          `xjoin?_join=cert.certificates,_j,inst.instruments&_on1=(cert.instrumentId,eq,inst.id)&_fields=cert.DUCID,inst.instrumentName,cert.calibrationDate,cert.id,cert.approvedBy,inst.id,cert.locationOfInstrument&_where=(cert.clientId,eq,${id})~and(cert.approvedBy,ne,null)`
      )
      .then((res) => {
        setCertificatesData(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  var pushArray = [];

  for (let i = 0; i < customer.length; i++) {
    pushArray.push({
      id: customer[i].id,
      label: customer[i].companyName,
    });
  }

  const initializeCertificateDataTable = (id) => {
    var pushArray = [];
    var uniqueLabels = new Set();

    for (let i = 0; i < certificatesData.length; i++) {
      const label = `${certificatesData[i].inst_instrumentName},${certificatesData[i].cert_DUCID}`;
      if (!uniqueLabels.has(label)) {
        pushArray.push({
          instrumentId: certificatesData[i].inst_id,
          ducId: certificatesData[i].cert_DUCID,
          label: label,
        });
        uniqueLabels.add(label);
      }
    }

    setFinalArray(pushArray);
  };

  const getData = (event) => {
    let whereConditions = "";
    let pagination_settings = "";

    if (instId && ducId) {
      whereConditions = `AND inst.id = ${instId} AND cert.DUCID = '${ducId}'`;
    }

    if (rowsPerPage != -1) {
      pagination_settings = `limit ${rowsPerPage} offset ${
        rowsPerPage * Number(page)
      }`;
    }
    let data = {
      query: `SELECT client.companyName, client.contact, client.address, inst.instrumentName, cert.calibrationDate, cert.locationOfInstrument, cert.nextDueDate, client.id AS client_id, cert.id AS certificate_id, cert.DUCID, cert.srfInstrumentId, cert.certificateNumber,replace(replace( cert.ranges , '#',''),'|',' to ') as ranges , replace(replace( cert.lc , '#',''),'|',' to ') as lc, cert.make, cert.model,srfInstruments.requestedDucName  FROM certificates AS cert LEFT JOIN instruments AS inst ON cert.instrumentId = inst.id LEFT JOIN clients AS client ON cert.clientId = client.id LEFT JOIN srfInstruments ON srfInstruments.id = cert.id WHERE cert.calibrationDate IS NOT NULL and srfInstruments.status = 1 and cert.clientId = ${company} ${whereConditions} `,
    };
    axiosWithToken
      .post(BASE_URL + `dynamic`, data)
      .then((res) => {
        setData(res.data);
        // FilterData();
      })
      .catch((error) => {
        console.log(error);
      });
  };



  const initializeRowTable = () => {
    var rowData = [];
    var idAr = [];
    for (let i = 0; i < data.length; i++) {
      idAr.push(data[i].certificate_id);
      rowData.push(
        createData(
          data[i].certificate_id,
          "ULR12123",
          data[i].certificateNumber,
          data[i].requestedDucName ? data[i].requestedDucName : data[i].instrumentName,
          data[i].ranges,
          data[i].lc,
          data[i].DUCID,
          data[i].make,
          data[i].model,
          data[i].locationOfInstrument,
          data[i].calibrationDate
            ? moment(data[i].calibrationDate).format("DD-MM-YYYY")
            : "",
          data[i].nextDueDate
            ? moment(data[i].nextDueDate).format("DD-MM-YYYY")
            : ""
        )
      );
    }
    setCalData(rowData);
    setIds(idAr);
  };
  useEffect(() => {
    getCustomer();
    //  getData();
  }, []);
  useEffect(() => {
    initializeRowTable();
  }, [data]);
  const search = () => {
    setSearched(true);
    getData();
  };
  const clearSearch = () => {
    setSearched(false); 
    setCompany("");
    setDucId("");
    setInstId(0);
    setCalData([]);
   };
  useEffect(() => {
    initializeCertificateDataTable();
  }, [certificatesData]);

  return (
    <ListWrap>

        <Grid
          container
          spacing={2}
          justifyContent={"start"}
          alignItems={"center"}
        >
          <Grid item xs={12} sm={12} md={3} lg={3}>
            <Autocomplete
              size="small"
              className="Listinputcss"
              id="combo-box-demo"
              options={pushArray}
              value={company ? { id: company, label: customer.find(c => c.id === company)?.companyName } : null}
              renderInput={(params) => <TextField {...params} label="Client" />}
              onChange={(event, value) => {
                if (value != null) {
                  setCompany(value.id);
                  getInstruments(value.id);
                } else {
                  setCompany("");
                }
                setSearched(false); 
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={3} lg={3}>
            <Autocomplete
              size="small"
              className="Listinputcss"
              id="combo-box-demo"
              options={finalArray}
              value={instId ? finalArray.find(inst => inst.instrumentId === instId) : null}
              renderInput={(params) => (
                <TextField {...params} label="Instruments" />
              )}
              onChange={(event, value) => {
                if (value != null) {
                  setInstId(value.instrumentId);
                  setDucId(value.ducId);
                } else {
                  setCompany("");
                }
                setSearched(false); 
              }}
            />
          </Grid>
          <Button
            variant="contained"
            size="small"
            sx={{ m: 0 }}
            color={searched ? "error" : "primary"}
            onClick={() => {
              searched ? clearSearch() : search();
            }}
            style={{ marginLeft: "10px",  marginTop: "1em" }}
          >
           {searched ? <RestartAltIcon /> : <SearchIcon />}
          </Button>
          {ids.length ? (
            <div style={{ marginTop: "11px" }}>
              <ExcelDownload
                finalData={calData}
                notNeededColumn={notNeededColumn}
              />
            </div>
          ) : (
            ""
          )}
        </Grid>

        <TableContainer>
        <Box sx={{ width: "100%", overflow: "hidden",padding: "5px 0px" }}>
          <TableContainer>
            <Table stickyHeader aria-label="sticky table" size="small"  sx={{ borderCollapse: "collapse" }}>
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      <b>{column.label}</b>
                    </TableCell>
                  ))}
                  <TableCell style={{ fontWeight: "bold" }}>
                    View Certificate
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {calData.length > 0 ? (
                calData
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    return (
                      <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                        {columns.map((column) => {
                          let value = row[column.id];

                          if (column.id == "id") {
                            value = page * rowsPerPage + index + 1;
                          }
                          return (
                            <TableCell key={column.id} align={column.align}>
                              {column.format && typeof value === "number"
                                ? column.format(value)
                                : value}
                            </TableCell>
                          );
                        })}
                        <TableCell>
                          <ButtonGroup
                            size="small"
                            aria-label="small button group"
                          >
                            <Tooltip
                              title="View Certificate"
                              placement="top-start"
                            >
                              <Button
                                component={Link}
                                to={`/certificate/viewCertificate/${row.id}`}
                              >
                                <PreviewIcon />
                              </Button>
                            </Tooltip>
                          </ButtonGroup>
                        </TableCell>
                      </TableRow>
                    );
                  })
                ) : (
                  <TableRow>
                    <TableCell colSpan={columns.length + 1} align="center">
                     <b>Data not found </b>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100, { value: calData.length, label: "All" }]}
              component="div"
              count={calData.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableContainer>
        </Box>
        </TableContainer>
      
    </ListWrap>
  );
}
