import { Autocomplete, Button, Grid, TextField, Toolbar } from "@mui/material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import React, { useEffect } from "react";
import { BASE_URL } from "../../global";
import axiosWithToken from "../../utils/components/axiosTokenConfig";
import { ToastContainer, toast } from "react-toastify";
import Tables from "../../utils/components/tables";
import PreviewIcon from "@mui/icons-material/Preview";
import DeleteIcon from "@mui/icons-material/Delete";
import SearchIcon from "@mui/icons-material/Search";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { getFormattedDates } from "../../utils/utils";

var columns = [
  {
    id: "id",
    label: "Sr. No.",
    align: "left",
    minWidth: 40,
  },
  {
    id: "companyName",
    label: "Client Name",
    align: "left",
    minWidth: 40,
  },
  {
    id: "companyAddress",
    label: "Client Address",
    align: "left",
    minWidth: 40,
  },
  {
    id: "instrumentName",
    label: "Instrument Name",
    align: "left",
    minWidth: 40,
  },
  {
    id: "ducId",
    label: "DUCID",
    align: "left",
    minWidth: 40,
  },

  {
    id: "calibrationDate",
    label: "Calibration Date",
    align: "left",
    minWidth: 40,
  },
  {
    id: "dueDate",
    label: "Due Date",
    align: "left",
    minWidth: 40,
  },
];

const ExternalCalibrationList = () => {
  const [searchBy, setSearchBy] = React.useState(
    sessionStorage.getItem("searchBy") || "DUCID"
  );
  const [searchKey, setSearchKey] = React.useState(
    sessionStorage.getItem("searchKey") || ""
  );
  const [totalRows, setTotalRows] = React.useState(0);
  const [data, setFinalData] = React.useState([]);
  const [searched, setSearched] = React.useState(false);
  const _clientId = localStorage.getItem("clientId");
  const _userType = localStorage.getItem("type");
  const [from, setFrom] = React.useState(null);
  const [to, setTo] = React.useState(null);
  const urlPath = useLocation();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [editAccess, setEditAccess] = React.useState(
    localStorage.getItem("editAccess")?.split(",").map(Number)
  );

  const getQuery = (count) => {
    let whereConditions = "";
    let pagination_settings = "";
  
    if (searchBy !== "" && searchKey != "") {
     
      let colName = {
        "	Client Name": "c.companyName",
        "Instrument Name": "i.instrumentName",
        DUCID: "ec.ducId",
      }[searchBy];
      whereConditions = `where ${colName} like '%${searchKey}%'`;
    }

    if (to != null && from != null) {
      const { fromDate, toDate } = getFormattedDates(from, to);
      if (whereConditions === "") {
        whereConditions = " where ";
      } else {
        whereConditions += " and ";
      }

      whereConditions += ` ec.dueDate between '${fromDate}' and '${toDate}' `;
    }

    // if (_userType == 3)
    //   whereConditions +=
    //     whereConditions.length > 0
    //       ? `and cert.clientId = ${_clientId}`
    //       : `and cert.clientId = ${_clientId}`;
    // whereConditions +=
    //   whereConditions.length > 0
    //     ? " AND cert.status = 1"
    //     : "AND cert.status = 1";

    if (rowsPerPage != -1) {
      pagination_settings = `limit ${rowsPerPage} offset ${
        rowsPerPage * Number(page)
      }`;
    }

    let data = {
      query: `SELECT ec.*, c.companyName, a.address as companyAddress, i.instrumentName FROM externalCalibration ec LEFT JOIN clients c ON ec.clientId = c.id LEFT JOIN addresses a ON ec.address = a.id LEFT JOIN instruments i ON ec.instrumentId = i.id ${whereConditions}  order by ec.id DESC ${pagination_settings}`,
    };

    if (count)
      data.query = `SELECT COUNT(*) AS no_of_rows FROM externalCalibration ec LEFT JOIN clients c ON ec.clientId = c.id LEFT JOIN addresses a ON ec.address = a.id LEFT JOIN instruments i ON ec.instrumentId = i.id ${whereConditions} `;
    return data;
  };

  // api calls
  function getTotalRows() {
    let data = getQuery(true);

    axiosWithToken
      .post(BASE_URL + `dynamic`, data)
      .then(async (res) => {
        setTotalRows(res.data[0]?.no_of_rows);
      })
      .catch((err) => {
        console.log("datasheet data fetching error: ", err);
        if (err.message !== "request_aborted") {
          toast.error(err.message);
        }
      });
  }

  function fetchFinalData() {
    getTotalRows();
    let data = getQuery();

    axiosWithToken
      .post(BASE_URL + `dynamic`, data)
      .then((res) => {
        setFinalData(res.data);
      })
      .catch((err) => {
        console.log("srfs data fetching error: ", err);
        if (err.message !== "request_aborted") {
          toast.error(err.message);
        }
      });
  }

  const handleDelete = (id) => {
    const confirmed = window.confirm(
      "Really want to delete externalCalibration?"
    );
    if (!confirmed) {
      return;
    }
    let url = BASE_URL;
    axiosWithToken
      .delete(url + `externalCalibration/${id}`)
      .then((res) => {
        toast("externalCalibration is deleted!");
        setTimeout(refresh, 500);
      })
      .catch((err) => {
        if (err.message !== "request_aborted") {
          toast.error(err.message);
        }
      });
  };

  const search = () => {
    sessionStorage.setItem("searchBy", searchBy);
    sessionStorage.setItem("searchKey", searchKey);
    sessionStorage.setItem("searched", "true");
    // sessionStorage.setItem("currentPath", urlPath.pathname);
    setSearched(true);
    fetchFinalData();
  };
  const clearSearch = () => {
    sessionStorage.removeItem("searchBy");
    sessionStorage.removeItem("searchKey");
    sessionStorage.removeItem("searched", searched);
    // sessionStorage.removeItem("currentPath");
    setSearchKey("");
    setSearchBy("DUCID");
    setFrom(null);
    setTo(null);

    setSearched(false);
    fetchFinalData();
  };

  var refresh = () => {
    window.location.reload(false);
  };

  useEffect(() => {
    fetchFinalData();
  }, [rowsPerPage, page]);

  const actions = [
    {
      id: "edit",
      tooltip: "Edit ExternalCalibration ",
      link: true,
      linkTo: (id) => `/externalCalibrationList/editExternalCalibration/${id}`,
      icon: <PreviewIcon />,
    },
    // {
    //   id: "copy",
    //   tooltip: "Copy DUC ",
    //   icon: <ContentCopyIcon />,
    //   handler: (id) => copyDUC(id),
    // },
    {
      id: "delete",
      tooltip: "Delete ExternalCalibration ",
      icon: (
        <DeleteIcon
          style={{
            color: !(editAccess?.includes(2) || editAccess?.includes(0))
              ? "lightgray"
              : "#dc3545",
          }}
        />
      ),
      handler: (id) => handleDelete(id),
      isDisabled: !(editAccess?.includes(2) || editAccess?.includes(0)),
    },
  ];

  return (
    <div style={{ padding: "10px" }}>
      <Toolbar id="externalCalibration" className="ListCreatebutton">
        <Button
          variant="contained"
          size="small"
          component={Link}
          to="/externalCalibrationList/editExternalCalibration/0"
          sx={{
            ":hover": {
              color: "#fff",
            },
          }}
        >
          <b>create External Calibration</b>
        </Button>
      </Toolbar>
      <br />

      <Grid
        container
        spacing={2}
        // justifyContent="flex-end"
        alignItems="center"
        style={{ padding: "5px 0px", marginBottom: "2px" }}
      >
        <Grid item xs={12} sm={6} md={2} lg={2} id="ducMaster_list_searchby">
          <Autocomplete
            sx={{
              m: 0,
              minWidth: 180,
            }}
            className="Listinputcss"
            size="small"
            value={searchBy}
            options={
              _userType != 3
                ? [
                    { key: "c.companyName", label: "	Client Name" },
                    { key: "i.instrumentName", label: "Instrument Name" },
                    { key: "ec.ducId", label: "DUCID" },
                  ]
                : [{ key: "c.companyName", label: "	Client Name" }]
            }
            renderInput={(params) => (
              <TextField {...params} label="Search By" />
            )}
            onInputChange={(event, newInputValue) => {
              setSearchBy(newInputValue);
              setSearched(false);
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={2} lg={2}>
          <TextField
            className="Listinputcss"
            id="ducMaster_list_searchbytext"
            label={"enter " + searchBy}
            size="small"
            inputProps={{
              autoComplete: "off",
            }}
            value={searchKey}
            variant="outlined"
            onChange={(e) => {
              setSearchKey(e.target.value);
              setSearched(false);
            }}
            style={{ width: "100%" }}
          />
        </Grid>
        <Grid item xs={6} sm={3} md={1} lg={1.5} id="srflist-datefrom">
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              slotProps={{
                textField: { size: "small", fullWidth: true },
              }}
              className="Listinputcss"
              label="From"
              value={from ? new Date(from) : null}
              inputFormat="dd/MM/yyyy"
              format="dd/MM/yyyy"
              onChange={(newValue) => {
                setFrom(newValue);
                setSearched(false);
              }}
              renderInput={(params) => <TextField {...params} size="small" />}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={6} sm={3} md={1} lg={1.5} id="srflist-dateto">
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              slotProps={{
                textField: { size: "small", fullWidth: true },
              }}
              className="Listinputcss"
              label="To"
              value={to ? new Date(to) : null}
              inputFormat="dd/MM/yyyy"
              format="dd/MM/yyyy"
              onChange={(newValue) => {
                setTo(newValue);
                setSearched(false);
              }}
              renderInput={(params) => <TextField {...params} size="small" />}
              style={{ marginRight: "20px" }}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={6} sm={2} md={1} lg={0.5} textAlign={"left"}>
          <Button
            id="ducMaster_list_searchbtn"
            variant="contained"
            size="small"
            color={searched ? "error" : "primary"}
            onClick={() => {
              searched ? clearSearch() : search();
              // handleFilterClose();
            }}
          >
            {searched ? <RestartAltIcon /> : <SearchIcon />}
          </Button>
        </Grid>
      </Grid>

      <Tables
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        handleDelete={handleDelete}
        columns={columns}
        finalData={data}
        actions={actions}
        totalRows={totalRows}
      />
    </div>
  );
};

export default ExternalCalibrationList;
